function formatOrganization({
  slug,
  type,
  name,
  // Shared Configs
  enableCourseCatalog,
  // Community (apprentage)
  enablePathways,
  enableWorkforceSuite,
  // Enterprise (workforce)
  enableUserDirectory,
  enableUserGroups,
  enableProjects,
  integrateMicrosoftTeams,
  // Stripe
  integration
}) {
  const data = {
    // Default Configs
    enableNewEditor: { 'en-US': true },
    // Can NOT integrate company's Slack Channel
    integrateSlack: { 'en-US': false },
    // Can NOT sell courses
    billing: { 'en-US': false },
    // Users must be invited
    restrictRegister: { 'en-US': true },
    // External courses only or allow create via LMS
    enableCourseCreateContent: { 'en-US': false }
  };

  if (slug !== undefined) {
    data.slug = {
      'en-US': slug
    };
  }

  if (type !== undefined) {
    data.type = {
      'en-US': type
    };
  }

  if (name !== undefined) {
    data.name = {
      'en-US': name
    };
  }

  // SHARED CONFIGS
  if (enableCourseCatalog !== undefined) {
    data.enableCourseCatalog = {
      'en-US': enableCourseCatalog
    };
  }

  // COMMUNITY (apprentage)
  if (enablePathways !== undefined) {
    data.enablePathways = {
      'en-US': enablePathways
    };
  }

  if (enableWorkforceSuite !== undefined) {
    data.enableWorkforceSuite = {
      'en-US': enableWorkforceSuite
    };
  }

  // ENTERPRISE (workforce)
  if (enableUserDirectory !== undefined) {
    data.enableUserDirectory = {
      'en-US': enableUserDirectory
    };
  }

  if (enableUserGroups !== undefined) {
    data.enableUserGroups = {
      'en-US': enableUserGroups
    };
  }

  if (enableProjects !== undefined) {
    data.enableProjects = {
      'en-US': enableProjects
    };
  }

  if (integrateMicrosoftTeams !== undefined) {
    data.integrateMicrosoftTeams = {
      'en-US': integrateMicrosoftTeams
    };
  }

  // STRIPE INTEGRATION
  if (integration !== undefined) {
    data.integration = {
      'en-US': integration
    };
  }

  return { fields: { ...data } };
}

export default formatOrganization;
