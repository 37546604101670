import { pathOr } from 'ramda';
import {
  findObjectByKey,
  getEmailsFromUsers,
  roleReadable,
  roleShouldStartInTurbineAdmin
} from '@apprentage/utils';
import {
  SET_SIGNUP,
  SET_SIGNUP_NOTIFY,
  SET_LOADING,
  LOGOUT,
  RESET_SIGNUP
} from './types';
import { TURBINE_ADMIN_EMAIL } from '../constants/globals';
import { sendEmailWelcomeUser, newUserNotifyOrg } from '../services/mailer';
import { TURBINE_ADMIN } from '../constants/urls';
import { getLoginLink } from '../services/auth';

export const setLoading = (loading) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING,
      loading
    });
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({
      type: LOGOUT
    });
  };
};

export const setSignUp = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_SIGNUP,
        signup: {
          ...data
        }
      });
      resolve();
    });
  };
};

export const updateSignUp = (data) => {
  return (dispatch, getState) => {
    const { signup } = getState();

    return new Promise((resolve) => {
      dispatch({
        type: SET_SIGNUP,
        signup: {
          ...signup,
          ...data
        }
      });
      resolve();
    });
  };
};

export const resetSignUp = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_SIGNUP
    });
  };
};

export const notifySignUp = ({
  email,
  name,
  userId,
  organization,
  locations,
  signup
}) => {
  return (dispatch) => {
    // Organization
    const orgSlug = pathOr('', ['slug'], organization);
    const orgType = pathOr('', ['type'], organization);
    // Location
    const location = findObjectByKey(locations, 'sys.id', signup?.locationId);
    const locationName = pathOr('', ['name'], location);
    const defaultLocation = pathOr(false, ['defaultLocation'], location);
    const managers = pathOr([], ['managers'], location); // TODO location flatten
    const observers = pathOr([], ['observers'], location); // TODO location flatten

    return new Promise((resolve) => {
      // TODO add reject and notify Turbine Admins
      const managersObservers = [...managers, ...observers];
      const bcc = getEmailsFromUsers(managersObservers);
      const messageUserLink = `${TURBINE_ADMIN}/org/users/${userId}?sendMsgUserId=${userId}`;
      let loginLink = getLoginLink(orgSlug);

      /**
       * Administrative Users are redirected to Turbine Admin
       *
       * owner = Organization Settings
       * programManger = Apprenticeship > Users
       * programTrainer = Apprenticeship > Users
       */
      if (roleShouldStartInTurbineAdmin([signup?.role])) {
        loginLink = signup?.continueUrl || TURBINE_ADMIN;
      }

      const emailData = {
        orgName: organization?.name,
        orgLogo: organization?.orgLogo,
        orgType: organization?.type,
        name,
        userRoleReadable: roleReadable([signup?.role], orgType)
      };

      const newUserNotifyOrgData = {
        ...emailData,
        to: organization?.orgEmail || TURBINE_ADMIN_EMAIL,
        membership: signup?.membership,
        locationName,
        defaultLocation,
        messageUserLink
      };

      if (bcc) newUserNotifyOrgData.bcc = bcc;

      const sendEmailWelcomeUserData = {
        ...emailData,
        role: signup?.role,
        to: email,
        organization,
        loginLink
      };

      // TODO set flag on org notifyUsersByEmail
      // implement firebase messaging and browser notifications to push messages to user

      Promise.all([
        sendEmailWelcomeUser(sendEmailWelcomeUserData),
        newUserNotifyOrg(newUserNotifyOrgData)
      ]).then(() => {
        dispatch({
          type: SET_SIGNUP_NOTIFY,
          emailData
        });

        resolve();
      });
    });
  };
};
