import { sign } from 'jsonwebtoken';
import {
  TURBINE_VELA,
  LOCAL_TURBINE_VELA,
  TURBINE,
  LOCAL_TURBINE,
  TURBINE_ADMIN,
  LOCAL_TURBINE_ADMIN,
  TURBINE_CONSOLE,
  LOCAL_TURBINE_CONSOLE
} from '../constants/urls';
import { FIREBASE_AUTH_RESET_PW } from '../constants/api';
import * as twApi from './tw-api';

const OLD_TURBINE_ADMIN_HOSTNAME = 'admin.turbinelms.com';
const NEW_TURBINE_ADMIN_HOSTNAME = 'admin.turbine.is';

/**
 * Generate Password Reset Link
 * @param {string} email
 * @returns {Promise<object>} - returns object containing password reset link
 */
export async function generatePasswordResetLink(email) {
  const response = await twApi.get(FIREBASE_AUTH_RESET_PW, { email });

  return response;
}

export const getLoginLink = (slug) => {
  return `${TURBINE}/login/${slug}`;
};

export const getRegisterOwnerLink = ({
  slug, orgId, email, name, phone
}) => {
  const continueUrlParams = {
    manageType: 'edit',
    orgId,
    contentType: 'organization'
  };
  const continueUrlQueryParams = new URLSearchParams(continueUrlParams);
  const continueUrl = `${TURBINE_ADMIN}/org/manage?${continueUrlQueryParams.toString()}`;

  const params = {
    action: 'signup',
    role: 'owner',
    userEmail: email,
    userName: name,
    userPhone: phone,
    canChangeName: true,
    canChangePhone: true,
    continueUrl
  };
  const searchParams = new URLSearchParams(params);

  return `${TURBINE}/login/${slug}?${searchParams.toString()}`;
};

export const locationSignUpParams = ({
  orgType,
  locations,
  userLocationId,
  canChangeLocation
}) => {
  const params = {};
  const locationsList = locations || [];

  // Set userLocationId as locationId if present in URL parameters
  params.locationId = userLocationId;

  // Community Organization Location Logic
  if (orgType === 'community' && !userLocationId) {
    const defaultLocation = locationsList.find(
      (l) => l?.defaultLocation === true
    );

    params.locationId = defaultLocation?.id;

    // Users registering for Community Organizations can
    // only see/change their location if a userLocationId
    // is passed in the URL parameters
    params.hideLocation = true;
    params.canChangeLocation = false;
  }

  // Enterprise Organization Location Logic
  if (orgType === 'workforce') {
    if (!userLocationId) {
      const [firstLocation] = locationsList;

      params.locationId = firstLocation?.id;
    }

    // It was NOT specified whether the user can change their location
    // and since there are multiple locations we give the user the option
    // to change their location. If there was only one location, we wouldn't
    // need to give them the option to change their location
    if (!canChangeLocation && locationsList.length > 0) {
      params.canChangeLocation = true;
    }
  }

  return params;
};

export const createRedirectUrl = (url = '') => {
  const decodedUrl = decodeURIComponent(url);

  switch (true) {
    case decodedUrl.includes(TURBINE_CONSOLE):
      return TURBINE_CONSOLE;
    case decodedUrl.includes(LOCAL_TURBINE_CONSOLE):
      return LOCAL_TURBINE_CONSOLE;
    case decodedUrl.includes(LOCAL_TURBINE_ADMIN):
      return LOCAL_TURBINE_ADMIN;
    case decodedUrl.includes(TURBINE_ADMIN):
    case decodedUrl.includes(OLD_TURBINE_ADMIN_HOSTNAME):
      return TURBINE_ADMIN;
    case decodedUrl.includes(TURBINE_VELA):
      return TURBINE_VELA;
    case decodedUrl.includes(LOCAL_TURBINE_VELA):
      return LOCAL_TURBINE_VELA;
    case decodedUrl.includes(LOCAL_TURBINE):
      return LOCAL_TURBINE;
    default:
      return TURBINE;
  }
};

export const redirectTo = ({ email, password, continueUrl }) => {
  // Example of continueUrl that includes old Turbine lms domain
  // ?continueUrl=https%3A%2F%2Fadmin.turbinelms.com%2Forg%2Fdashboard%3ForgId%3D...

  const redirectUrl = createRedirectUrl(continueUrl);
  const secret = process.env.REACT_APP_AUTH_TOKEN_SECRET;
  const token = sign({ email, password }, secret);
  const urlParams = {
    token,
    provider: 'turbine',
    ...(continueUrl ? { continueUrl } : {})
  };

  if (continueUrl && continueUrl.includes(OLD_TURBINE_ADMIN_HOSTNAME)) {
    urlParams.continueUrl = continueUrl.replace(OLD_TURBINE_ADMIN_HOSTNAME, NEW_TURBINE_ADMIN_HOSTNAME);
  }

  const searchParams = new URLSearchParams(urlParams);

  window.location = `${redirectUrl}/sso?${searchParams.toString()}`;
};
