import React from 'react';
import { useSelector } from 'react-redux';
import { TURBINE_LOGO } from '@apprentage/constants';
import { PLATFORM_NAME } from '../../../constants/globals';
import './style.css';

const Logo = ({ src }) => {
  const organization = useSelector((state) => state.organization);
  let url = src || TURBINE_LOGO;
  let name = PLATFORM_NAME;

  if (organization) {
    const {
      name: orgName,
      orgLogo
    } = organization;

    name = orgName;
    if (orgLogo) {
      url = orgLogo;
    } else {
      url = '';
    }
  }

  if (url) {
    return (
      <div className="authpage-logo mb-3">
        <img src={url} alt={name} className="w-100" />
      </div>
    );
  }

  return (
    <h2 className="h5 font-weight-bold px-3 mb-3">
      {name}
    </h2>
  );
};

export default Logo;
