import React, { useEffect } from 'react';
import Legal from './Legal';
import Logo from './Logo';
import PoweredBy from './PoweredBy';
import './style.css';

const Container = ({
  title,
  className,
  children,
  orgLogo,
  showLegal = true,
  showPoweredBy = true,
  showLogo = true,
  bodyClassName = ''
}) => {
  useEffect(() => {
    document.getElementsByTagName('html')[0].className = `authpage ${bodyClassName}`;
  }, []);

  return (
    <div className={`auth-container ${className}`}>

      <div className="card bg-light rounded">
        {title && (
          <h5 className="card-header py-3 h6 text-capitalize">
            {title}
          </h5>
        )}

        <div className="card-body">
          {showLogo && (
            <Logo src={orgLogo} />
          )}

          {children}
        </div>

        {showLegal && (
          <div className="card-footer px-2">
            <Legal />
          </div>
        )}
      </div>

      {showPoweredBy && (
        <div className="mt-3 mb-3 text-muted">
          <PoweredBy />
        </div>
      )}
    </div>
  );
};

export default Container;
