function formatLocation({
  name,
  orgId
}) {
  const data = {};

  data.enableNewEditor = { 'en-US': true };
  data.defaultLocation = { 'en-US': true };

  if (name !== undefined) {
    data.name = {
      'en-US': name
    };
  }

  if (orgId !== undefined) {
    data.orgId = {
      'en-US': orgId
    };
  }

  return { fields: { ...data } };
}

export default formatLocation;
